import { useCallback, useEffect } from "react";
import { useSendAnalyticsEvent } from "./useSendAnalyticsEvent";
var version = "2021-09-v2";
var category = "Primary Signup";
// ${object} ${action} (Proper Case) https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
var events = [
    "Primary Signup Started",
    "Pledge Existed",
    "Employment Status Selected",
    "Pledge Type Selected",
    "Currency Code Selected",
    "All Impact Fields Selected",
    "Names Entered",
    "Primary Signup Completed",
    "Primary Signup Submitted", 
];
/**
 * Links the state of useSignUpContext to analytics events
 */ export var usePrimaryFlowAnalytics = function(pledgeContext) {
    var sendEvent = useSendAnalyticsEvent({
        category: category,
        version: version,
        events: events
    }).sendEvent;
    var _pledge = pledgeContext.pledge, pledgeType = _pledge.pledgeType, pledgeId = _pledge.id, _income = pledgeContext.income, employmentStatus = _income.employmentStatus, currencyCode = _income.currencyCode, _person = pledgeContext.person, firstName = _person.firstName, lastName = _person.lastName, success = pledgeContext.mutation.success, _derivedState = pledgeContext.derivedState, hasActivePledge = _derivedState.hasActivePledge, hasValidImpactFields = _derivedState.hasValidImpactFields, isValid = _derivedState.isValid;
    useEffect(function() {
        sendEvent("Primary Signup Started");
    }, []);
    useEffect(function() {
        if (hasActivePledge) {
            sendEvent("Pledge Existed");
        }
    }, [
        hasActivePledge
    ]);
    useEffect(function() {
        if (employmentStatus) {
            sendEvent("Employment Status Selected", {
                employmentStatus: employmentStatus
            });
        }
    }, [
        employmentStatus
    ]);
    useEffect(function() {
        if (pledgeType) {
            sendEvent("Pledge Type Selected", {
                pledgeType: pledgeType
            });
        }
    }, [
        pledgeType
    ]);
    useEffect(function() {
        if (currencyCode) {
            sendEvent("Currency Code Selected", {
                currencyCode: currencyCode
            });
        }
    }, [
        currencyCode
    ]);
    useEffect(function() {
        if (hasValidImpactFields) {
            sendEvent("All Impact Fields Selected");
        }
    }, [
        hasValidImpactFields
    ]);
    useEffect(function() {
        if (firstName && lastName) {
            sendEvent("Names Entered");
        }
    }, [
        firstName,
        lastName
    ]);
    useEffect(function() {
        if (isValid) {
            sendEvent("Primary Signup Completed");
        }
    }, [
        isValid
    ]);
    var sendSubmitEvent = useCallback(function() {
        sendEvent("Primary Signup Submitted", {
            pledgeType: pledgeType,
            label: pledgeType,
            pledgeId: pledgeId
        });
    }, [
        pledgeType,
        pledgeId
    ]);
    useEffect(function() {
        if (success && pledgeId) {
            sendSubmitEvent();
        }
    }, [
        success,
        pledgeId
    ]);
};
