import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { useCallback } from "react";
import { useAnalytics } from "../../../../../utils/analytics";
export var useSendAnalyticsEvent = function(param) {
    var category = param.category, version = param.version;
    var analytics = useAnalytics();
    var sendEvent = useCallback(function(event, properties) {
        if (!analytics) {
            // eslint-disable-next-line no-console
            console.debug("Analytics: ".concat(event, " - ").concat(JSON.stringify(properties)));
            return;
        }
        analytics.track(event, _object_spread({
            version: version,
            category: category
        }, properties));
    }, [
        analytics
    ]);
    return {
        sendEvent: sendEvent
    };
};
