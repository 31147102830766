import { DateTime } from "luxon";
import { z } from "zod";
import { IncomeEmploymentStatus, PledgeType } from "@aletheia/graphql/graphql";
import { DateTimeZ, DurationZ, expectType } from "./utils";
// Income percentages
var GWWC_PLEDGE_MIN_INCOME_PERCENTAGE = 10;
var GWWC_PLEDGE_MAX_INCOME_PERCENTAGE = 99;
var TRIAL_PLEDGE_MIN_INCOME_PERCENTAGE = 1;
var TRIAL_PLEDGE_MAX_INCOME_PERCENTAGE = 99;
// Wealth percentages
var GWWC_PLEDGE_MIN_WEALTH_PERCENTAGE = 1;
var GWWC_PLEDGE_MAX_WEALTH_PERCENTAGE = 99.9;
var TRIAL_PLEDGE_MIN_WEALTH_PERCENTAGE = 0.1;
var TRIAL_PLEDGE_MAX_WEALTH_PERCENTAGE = 99.9;
/* INCOME */ export var SimpleIncomeSchema = z.object({
    id: z.string().nullish(),
    amount: z.string().nullish(),
    currencyCode: z.string(),
    employmentStatus: z.nativeEnum(IncomeEmploymentStatus)
});
/* WEALTH */ export var SimpleWealthSchema = z.object({
    amount: z.string().nullish()
}).nullish();
/* PLEDGE */ export var SimplePledgeSchema = z.object({
    id: z.string().nullish(),
    pledgeType: z.enum([
        PledgeType.GivingWhatWeCan,
        PledgeType.TryGiving
    ]),
    incomePercentage: z.number().min(1).max(99),
    wealthPercentage: z.number().min(0).max(99.9).nullish(),
    startDate: DateTimeZ.refine(function(val) {
        return isValidStartDate(val);
    }),
    duration: z.union([
        DurationZ,
        z.literal("infinity")
    ]),
    motivation: z.string().nullish(),
    shareMotivation: z.boolean().nullish(),
    locale: z.string().nullish(),
    termsAgreed: z.boolean(),
    clubTermsAgreed: z.boolean().nullish(),
    pledgeClub: z.string().nullish()
});
export var isValidIncomePercentage = function(incomePercentage, pledgeType) {
    if (!pledgeType || !incomePercentage) return false;
    if (pledgeType === PledgeType.GivingWhatWeCan) {
        return incomePercentage >= GWWC_PLEDGE_MIN_INCOME_PERCENTAGE && incomePercentage <= GWWC_PLEDGE_MAX_INCOME_PERCENTAGE;
    }
    if (pledgeType === PledgeType.TryGiving) {
        return incomePercentage >= TRIAL_PLEDGE_MIN_INCOME_PERCENTAGE && incomePercentage <= TRIAL_PLEDGE_MAX_INCOME_PERCENTAGE;
    }
    return false;
};
export var isValidWealthPercentage = function(wealthPercentage, pledgeType) {
    if (!pledgeType) return false;
    if (!wealthPercentage) return true;
    if (pledgeType === PledgeType.GivingWhatWeCan) {
        return wealthPercentage >= GWWC_PLEDGE_MIN_WEALTH_PERCENTAGE && wealthPercentage <= GWWC_PLEDGE_MAX_WEALTH_PERCENTAGE;
    }
    if (pledgeType === PledgeType.TryGiving) {
        return wealthPercentage >= TRIAL_PLEDGE_MIN_WEALTH_PERCENTAGE && wealthPercentage <= TRIAL_PLEDGE_MAX_WEALTH_PERCENTAGE;
    }
    return false;
};
export var isValidStartDate = function(startDate) {
    // Start date is mandatory
    if (!startDate) return false;
    var currentDate = DateTime.now();
    var minStartDate = currentDate.minus({
        years: 10
    });
    var maxStartDate = currentDate.plus({
        years: 10
    });
    return startDate >= minStartDate && startDate <= maxStartDate;
};
/* ADDRESS */ export var AddressUnsavedSchema = z.object({
    id: z.string().nullish(),
    firstName: z.string().min(1).nullish(),
    lastName: z.string().min(1).nullish(),
    houseNumber: z.string().min(1).nullish(),
    addressLine1: z.string().min(1).nullish(),
    addressLine2: z.string().nullish(),
    city: z.string().min(1).nullish(),
    region: z.string().min(1).nullish(),
    postalCode: z.string().min(1).nullish(),
    countryCode: z.string().min(2).max(2).nullish(),
    latitude: z.number().nullish(),
    longitude: z.number().nullish(),
    googlePlaceId: z.string().nullish()
});
expectType({});
expectType({});
/* PERSON */ export var PersonNameSchema = z.object({
    firstName: z.string().min(1).nullish(),
    lastName: z.string().min(1).nullish()
});
// SELECT (string_to_array(email, '@')) [2], count(*) FROM people.email_address GROUP BY 1 ORDER BY 2 DESC;
// We could also use something like https://github.com/ZooTools/email-spell-checker
var INVALID_DOMAINS = [
    "b.c",
    "example.com",
    "gamil.com",
    "gmai.com",
    "gmail.c",
    "gmail.co",
    "gmail.cok",
    "gmail.coma",
    "gmail.comc",
    "gmail.con",
    "gmail.cpm",
    "gnail.com",
    "hotmail.c",
    "hotmail.co",
    "hotmail.con",
    "hotnail.com",
    "msn.con",
    "outlook.om",
    "yandex.tu", 
];
export var PersonEmailSchema = z.object({
    email: z.string().min(5).email().nullish().refine(function(val) {
        return !val || !INVALID_DOMAINS.some(function(domain) {
            return val.endsWith("@".concat(domain));
        });
    }, "Invalid email domain").or(z.literal("").transform(function() {
        return null;
    }))
});
export var PersonCountryResidenceSchema = z.object({
    countryCode: z.string().nullish()
});
export var isValidBirthDate = function(birthDate) {
    // Birth date is optional
    if (!birthDate) return true;
    var currentDate = DateTime.now();
    var currentYear = currentDate.year;
    var maxBirthDate = currentDate.minus({
        years: 100
    });
    return birthDate.year <= currentYear && birthDate <= currentDate && birthDate >= maxBirthDate;
};
export var PersonBirthDateSchema = z.object({
    birthDate: DateTimeZ.refine(function(val) {
        return isValidBirthDate(val);
    }).nullish()
});
export var TemporaryPersonSchema = z.object({
    sessionToken: z.string().uuid().nullish()
});
export var PersonSchema = PersonNameSchema.merge(PersonBirthDateSchema).merge(PersonCountryResidenceSchema).merge(PersonEmailSchema).merge(TemporaryPersonSchema);
export var PersonWithAddressFormSchema = AddressUnsavedSchema.merge(PersonNameSchema);
